// extracted by mini-css-extract-plugin
export var authorNameWrap = "blogPost-module--authorNameWrap--0fbfe";
export var autorWrap = "blogPost-module--autorWrap--95df6";
export var categories = "blogPost-module--categories--e4ebb";
export var container = "blogPost-module--container--b4cb0";
export var excerpt = "blogPost-module--excerpt--1424f";
export var facebook = "blogPost-module--facebook--2da06";
export var grid = "blogPost-module--grid--417cd";
export var hero = "blogPost-module--hero--c0372";
export var heroContent = "blogPost-module--heroContent--3112c";
export var linkedin = "blogPost-module--linkedin--0eb62";
export var metaWrap = "blogPost-module--metaWrap--583d7";
export var mobileTitle = "blogPost-module--mobileTitle--8265b";
export var name = "blogPost-module--name--6578b";
export var postContent = "blogPost-module--postContent--43fd3";
export var publishedAt = "blogPost-module--publishedAt--8795f";
export var publishedDate = "blogPost-module--publishedDate--4e477";
export var shareTitle = "blogPost-module--shareTitle--6c6ae";
export var smallHeader = "blogPost-module--smallHeader--93aa5";
export var socialList = "blogPost-module--socialList--5a75a";
export var title = "blogPost-module--title--db2eb";
export var twitter = "blogPost-module--twitter--eaf81";
export var whatsapp = "blogPost-module--whatsapp--14ab1";
export var wrap = "blogPost-module--wrap--15d0a";
export var written = "blogPost-module--written--98384";